/* global mediaUtils, siteSettings */

// Initializing Cub forms (other than lead forms that are init-ed in the
// backend code)
// WARNING! Don't use jQuery here, or blank pages will get broken!

mediaUtils.domReady(() => {
  /**
   * This function will be called when Cub widget finishes initializing.
   * Since it used selectors to find and populate the forms, defining it
   * inside the DOMContentLoaded handler
   *
   * @param {Object} cub - Cub widget object, populated by the initializing
   * script
   */

  window.cubAsyncInit = (cub) => {
    /**
     * Looks for the element that could contain HTML to be shown on this form
     * successful submit, and returns that element's innerHTML (if found)
     *
     * @param {String} formBlockId - the id of the form element direct holder
     * without the hash symbol at the beginning
     *
     * @return {String|Boolean} The success message in a form of HTML,
     * or false if the element is not found.
     */
    function _findSuccessMsg(formBlockId) {
      const successContainer = document.querySelector(
        `[data-cub-form-success-msg="${formBlockId}"]`,
      );
      if (successContainer) {
        return successContainer.innerHTML;
      }
      return false;
    }

    // It's neccessary to use wrappers insted of direct calls of
    // window.cubLeadFormsSettings.onSuccess,
    // because not all sites have Cub Lead Forms (e.g. the M1 site uses
    // subscription forms, but doesn't uses lead forms).
    /* eslint-disable consistent-return */
    function _onSuccess(formData, formElement, response) {
      if (typeof window.cubLeadFormsSettings !== 'undefined') {
        return window.cubLeadFormsSettings.onSuccess(
          formData, formElement, response,
        );
      }
    }
    /* eslint-enable consistent-return */

    // Do nothing if the api key is not set
    if (!window.siteSettings || siteSettings.cubPublicKey === undefined) {
      return;
    }

    const cubConfig = {
      apiKey: siteSettings.cubPublicKey,
      apiUrl: siteSettings.cubApiUrl,
      // Not initializing all the forms here for 1) better file structuring,
      // 2) to be able to init similar forms in a loop, w/o code repeats
      forms: {},
      siteSentry: window.Sentry,
      staticUrl: siteSettings.lidCdnDomain ?
        `https://${siteSettings.lidCdnDomain}/static/` : undefined,
    };

    if (siteSettings.cubCustomMenuItems === true) {
      cubConfig.customUserMenuItems = [
        {
          name: 'member-home-page',
          title: 'Member Home Page',
          href: '/member-home-page/',
        },
        {
          name: 'my-jobs',
          title: 'My jobs',
          href: '/profile/jobs/',
        },
        {
          name: 'my-companies',
          title: 'My Companies',
          href: '/profile/companies/',
        },
      ];
    } else if (Array.isArray(siteSettings.cubCustomMenuItems)) {
      cubConfig.customUserMenuItems = siteSettings.cubCustomMenuItems;
    }

    // --------------------------------------------------------------------
    // Pre-built forms
    // --------------------------------------------------------------------

    [].slice.call(document.querySelectorAll('[data-lid-form="prebuilt"]'))
      .forEach((form) => {
        const id = form.getAttribute('data-lid-form-id');
        if (!id) { return; }
        // eslint-disable-next-line no-param-reassign
        form.id = id;
        // eslint-disable-next-line no-param-reassign
        form.className += 'Content-item';
        window.cubLeadForms = window.cubLeadForms || [];
        window.cubLeadForms.push({
          id: `#${id}`,
          load: id,
        });
      });

    // --------------------------------------------------------------------
    // Cub lead forms
    // --------------------------------------------------------------------

    /**
     * Working with form configs that are defined in other places: other JS,
     * inline JS, etc. This is a necessity for the Grants case, as there is an
     * admin interface there to create custom forms (add/remove predefined
     * fields, add customized fields).
     * How to use: define somewhere BEFORE this script a global var
     * `cubLeadForms`, an array of objects with props:
     * • id (e.g. '#form-id') the id of an el to load form into
     * • isPopup --  `false` will set the usual handler ("form body hides,
     *   Thank You appears"), `true` - must be set if the form is a popup
     * • action -- Put some absolute URL here to handle the form submit
     * • fieldsets -- array of objects, see Cub docs
     * If multiple forms are needed to be defined in multiple places, then
     * add to existing array:
      <div id="form-assistance-request"></div>
      <script>
        (function () {
          if (window.cubLeadForms === undefined) {
              window.cubLeadForms = [];
          }

          window.cubLeadForms.push({
            id: '#form-assistance-request',
            isPopup: false,
            action: 'leads',
            fieldsets: [ ... ],
            submit: 'Submit'
          });
        })();
      </div>
    */

    if (window.cubLeadForms !== undefined) {
      window.cubLeadForms.forEach((customForm) => {
        const form = customForm;
        const id = form.id;

        form.onSuccess = _onSuccess;

        const successMessage = _findSuccessMsg(id.substring(1));
        // The form object might have a success message prop already defined;
        // so we want to override it only if there is actual success HTML on
        // the page
        if (successMessage) {
          form.successMsgHTML = successMessage;
        }

        // To not sent to Cub any (now) junk
        delete (form.id);
        delete (form.isPopup);

        if (id === '#submit-directory-listing') {
          form.fieldsets[0].fields[0].onSearchResult = (
            window.onOrganizationSearchResultHandler
          );
        }
        if (id === '#add-agency-form' &&
              window.onOrganizationSearchResultHandler) {
          form.fieldsets[0].fields[1].onSearchResult = (
            window.onOrganizationSearchResultHandler
          );
        }

        cubConfig.forms[id] = form;
      });
    }

    // --------------------------------------------------------------------
    // Small sidebar subscription forms
    // --------------------------------------------------------------------

    // There are several forms of this type across the site (Career
    // newsletter, etc.) that are different only by the newsletter ID. Plus,
    // in theory there can multiple forms of this type even on a single page.
    // So init-ing those in a loop, for DRY.
    const subscribeFormHolders = [].slice.call(
      document.querySelectorAll('[data-cub-form="subscription-small"]'),
    );
    subscribeFormHolders.forEach((subscribeFormHolder) => {
      // id is needed because Cub picks only one element with a selector
      const id = subscribeFormHolder.getAttribute('id');
      const mailingListId = subscribeFormHolder.getAttribute('data-newsletter');
      const labelText = subscribeFormHolder.getAttribute('data-label-text');
      let submitText = subscribeFormHolder.getAttribute('data-submit-text');
      const responsiveBreakpoint = subscribeFormHolder.getAttribute(
        'data-responsive-breakpoint',
      );
      const columnWidth = subscribeFormHolder.getAttribute('data-column-width');
      const submitColumnWidth = subscribeFormHolder.getAttribute(
        'data-submit-column-width',
      );

      if (submitText === null) {
        submitText = 'Sign up now';
      }

      if (id === null || mailingListId === null) {
        return;
      }

      cubConfig.forms[`#${id}`] = {
        action: 'subscribers',
        responsiveBreakpoint: responsiveBreakpoint || 700,
        fieldsets: [{
          name: 'column',
          columnWidth: columnWidth || 100,
          fields: [{
            name: 'subscribe',
            type: 'hidden',
            value: mailingListId,
          }, {
            name: 'email',
            type: 'text',
            placeholder: 'Enter your email address',
            label: labelText,
            required: true,
          }],
        }],
        submitColumnWidth: submitColumnWidth || 100,
        submit: submitText,
        successMsgHTML: _findSuccessMsg(id) || null,
        onSuccess: _onSuccess,
      };
    });

    cub.start(cubConfig);
  };
});
