/**
 * Working with tables.
 * Unfortunately we don't have other means for putting a hidden header content
 * inside a table cell right now for generic tables (created in CKEditor), so
 * a JS solution is an only option.
 */
document.addEventListener('DOMContentLoaded', () => {
  function processTable(table) {
    // Check if table doesn't have 'tbody > tr' (tableExtraClass would be empty)
    const totalColumnCount = table.querySelector('tbody > tr') &&
      table.querySelector('tbody > tr').children.length || 0;
    // Luckily, CKEditor marks "headers" with corresponding scope attribute
    const topHeaders = [].slice.call(table.querySelectorAll('[scope="col"]'));
    // var vertHeaders =
    // [].slice.call(table.querySelectorAll('[scope="row"]'));
    let tableExtraClass;

    switch (totalColumnCount) {
      case 1:
        tableExtraClass = '';
        break;
      case 2:
        tableExtraClass = 'Table--blockMobileL';
        break;
      case 3:
        tableExtraClass = 'Table--blockMobileXXL';
        break;
      case 4:
        tableExtraClass = 'Table--blockTabletS';
        break;
      // 5 and more
      default:
        tableExtraClass = 'Table--blockTabletS Table--fixed';
        break;
    }
    // TODO: need to work this out
    // eslint-disable-next-line no-param-reassign
    table.className += ` Table ${tableExtraClass} Table--borders`;

    if (topHeaders.length > 0) {
      // Setting all actual headers to hide in a collapsed (mobile) view
      topHeaders.forEach((th, i) => {
        topHeaders[i].className += ' Table-header Table-header--hideCollapsed';
      });

      [].slice.call(table.querySelectorAll('tr')).forEach((tr, i) => {
        // No need to process the row with headers
        if (topHeaders.length && i === 0) {
          return;
        }

        /* eslint-disable no-param-reassign */
        tr.className += ' Table-row';
        /* eslint-enable no-param-reassign */

        [].slice.call(tr.children).forEach((td, j) => {
          // A cell can have some elements inside it (<stong>, etc). In that
          // case if we make the cell flex, it will get messed. So we need to
          // wrap its contents into something first, and only then add an
          // artifical caption. Like:
          // `td>(.Table-cellCaption{caption text} +
          // .Table-cellContent{initial content})`
          const tdContent = document.createElement('div');
          tdContent.className = 'Table-cellContent';
          tdContent.innerHTML = td.innerHTML;

          const tdCaption = document.createElement('div');
          tdCaption.className = 'Table-cellCaption';
          tdCaption.innerHTML = topHeaders[j]
            .textContent.replace(/\r?\n|\r/, '');

          // TODO: need to work this out
          /* eslint-disable no-param-reassign */
          td.innerHTML = '';
          // To activate whatever class selectors we have for table cells
          td.className += ' Table-cell';
          /* eslint-enable no-param-reassign */
          td.appendChild(tdCaption);
          td.appendChild(tdContent);
        });
      });
    }
  }

  [].slice.call(document.querySelectorAll('.Content table'))
    .forEach(processTable);
});
