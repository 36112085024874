/* global mediaUtils */

import $ from 'jquery';

import { ajaxTools } from 'ajax/ajax-tools.js';

/*
  Contents:

  * Working with links
  * Working with ajax forms
  * Monitoring history updates
  * -----------------------------------------------------------------------

  The functionality for loading content via ajax on button/link click.
  Browser history updates (the backend should send "X-TargetContainerSelector"
  response header with XHR responses, containing the selector of an element to
  be updated) and extra classes on active elements are supported.
  Can also work with forms: sending data, updating target containers, updating
  the form's fields on history pops (updatin ONLY WORKS for text/date inputs,
  radios and selects for now; no checkboxes), using /pathname/ instead of
  &get=params for selected fields (limited to selects, radios).

  Exmaple markup:
    <div class="Pagination Pagination--compact" data-ajax-links-scope>
      <a class="Pagination-item" href="..."
        data-ajax-load data-ajax-target="#target"
        data-history-update
        data-ajax-class="is-current"
      >All</a>
      ...
    </div>

  Attributes
  • data-ajax-load - give this attr to a link, on click an ajax request will
    be sent its `href` value page;
  • data-ajax-target - pass a selector of an element, inside which to put the
    loaded HTML
  • data-history-update - if present (no value needed), the browser's address
    bar will be updated, a new item in browser history will appear
  • data-ajax-class - the value of this attr will be added to the item's class
    if the request is successful.
  • data-ajax-form - set this (no value by default) to make a form and ajax one
    Possible values (could be combined):
    - 'no-ajax': if no actual ajax request needs to be sent, but all other
      things from here should work.
    - 'upd-on-change': if a form needs to be submitted when its fields change
  • data-ajax-callback - the name of a global function to fire when the ajax
    request has succeeded

  • data-ajax-links-scope - if set on the el's ANCESTOR, all the other els
    with `data-ajax-load` inside it will be stripped of the extra success
    class
  • data-ajax-url-path-updater - give this attr to a form control if you want
    its value not to be sent in a form of a GET-param, but as pathname part.
    E.g. not `/page/?p1=val1&p2=val2`, but `/page/val1/?p2=val2`

  Example form markup (very simple, almost no layout - just the vital attrs):
  <form class="Form" action="..." data-ajax-form data-ajax-target="#job-list"
    data-history-update method="get">
    <input class="Form-control Form-control--input" type="text"
      id="job-search-query" name="job-search-query"
      placeholder="Search Position, Level">
    <select class="Form-control Form-control--select" id="job-search-location"
      name="job-search-location" data-ajax-url-path-updater>
      <option value="none">Select location</option>
      <option value="baltimore-maryland">Baltimore, Maryland</option>
    </select>
    <button class="Button" type="submit" name="submit">
      Search
    </button>

    <!-- Error message, can be filled with arbitrary text -->
    <div class="Form-errorMessage">
      An error occured. Please try again later.
    </div>
  </form>
*/

$(() => {
  // -----------------------------------------------------------------------
  // Working with links
  // -----------------------------------------------------------------------

  $(document).on('click', ajaxTools.ajaxTriggerLinkSelector, function (e) {
    const $this = $(this);
    const url = $this[0].getAttribute('href');
    const targetSelector = $this[0]
      .getAttribute(ajaxTools.ajaxTriggerTargetAttr);
      // Will put the loaded HTML inside this element
    const $target = targetSelector && $(targetSelector);
    const shallUpdateHistory = $this[0]
      .hasAttribute(ajaxTools.historyUpdateAttr);

    // Additional class to be added to the button upon success
    const successClassExtra = $this[0]
      .getAttribute(ajaxTools.ataxTriggerSuccessClassAttr);
    const $parent = $this.closest(ajaxTools.ajaxScopeSel);

    if ($target.length === 0 || !url) {
      return false;
    }

    // If a request has already been sent by this button/link, do nothing
    if (ajaxTools.checkTriggerLoading($this[0])) {
      return false;
    }

    ajaxTools.setTriggerLoading($this[0]);

    $.get(url, (result) => {
      $target.html(result);
      // Reset the "this link's request is already sent" flag
      ajaxTools.unsetTriggerLoading($this[0]);

      // Rm the extra class from either the button or all its "siblings"
      if (successClassExtra) {
        if ($parent.length) {
          $parent.find(ajaxTools.ajaxTriggerLinkSelector)
            .removeClass(successClassExtra);
        }
        // Add extra success class to the current element
        $this.addClass(successClassExtra);
      }

      // Init all smartdate elements inside the HTML being loaded
      if (window.smartdate) {
        window.smartdate.render();
      }

      // If browser history update is needed (new url in the address bar)
      if (shallUpdateHistory) {
        history.pushState({}, 'Next page', url);
      }
    });

    e.preventDefault();
    return false;
  });

  // -----------------------------------------------------------------------
  // Working with ajax forms
  // -----------------------------------------------------------------------

  // For forms that needs to send requests when their fields change (w/o button
  // clicks)
  document.addEventListener('change', (e) => {
    const control = e.target;
    const $form = $(control).closest('[data-ajax-form]');
    if (!$form.length ||
      $form[0].getAttribute('data-ajax-form').search('upd-on-change') === -1
    ) { return; }

    // just form.submit() won't trigger the 'submit' event we are listening on
    const submitEvt = new CustomEvent('submit', {
      bubbles: true,
      cancelable: true,
    });
    $form[0].dispatchEvent(submitEvt);
  });

  // Sending serialized data via XHR on submit, playing with .is-error,
  // .is-processing classes, updating the address bar

  $(document).on('submit', '[data-ajax-form]', function (e) {
    const $this = $(this);
    // There are forms that don't need XHR/dynamic containers update, but do
    // need other functionality of this block (updating pathname, stripping
    // empty params)
    const isAjaxRequestNeeded = $this[0].getAttribute('data-ajax-form')
      .search('no-ajax') === -1;
    const isEncodeFormData = $this[0]
      .hasAttribute('data-ajax-form-encode-data');
    const targetSelector = $this[0]
      .getAttribute(ajaxTools.ajaxTriggerTargetAttr);
    const $target = targetSelector && $(targetSelector);
    // The base part of the URL (like `/law-enforcement-jobs/`)
    let formAction = $this[0].getAttribute('action') || '';
    // Serialize the form's data into a query string and remove params with
    // empty values
    let formData = $this.serializeForm().replace(/[^&?=]+?=(&|$)/g, '')
      .replace(/\&$/, '');
    // We'll be puting the loaded HTML inside this element
    const shallUpdateHistory = (
      $this[0].hasAttribute(ajaxTools.historyUpdateAttr)
    );
    // Fields whose values must contribute to location.pathname.
    // E.g. was `/path/`, now `/name/value01/value02/`
    const formFieldsUpdUrl = (
      $this[0].querySelectorAll('[data-ajax-url-path-updater]')
    );

    const formLoadingClass = 'is-processing';
    const formErrorClass = 'is-error';
    const formSuccessClass = (
      $this[0].hasAttribute('data-success-class') || 'is-success'
    );
    let tempRegexp = '';
    let i;

    e.preventDefault();

    // If a request has already been sent by submitting this form, do nothing
    if (ajaxTools.checkTriggerLoading($this[0])) {
      return false;
    }

    // Add "Loading" state, remove "Error" and "Success" states
    $this.removeClass(`${formSuccessClass} ${formErrorClass}`);
    $this.addClass(formLoadingClass);

    // If there are fields that should change the pathname, we're going to
    // rebuild the URL parts for sending e.g. `/page/val1/?p2=val2` instead
    // of `/page/?p1=val1&p2=val2`
    if (formFieldsUpdUrl.length !== 0) {
      for (i = 0; i < formFieldsUpdUrl.length; i++) {
        // Removing the GET-param
        tempRegexp = new RegExp(
          `(^|[?&])${formFieldsUpdUrl[i].name}=[^&]*([&]|$)`,
        );
        formData = formData.replace(tempRegexp, '$1');

        // Adding to a /path/
        if (formFieldsUpdUrl[i].value === '') { continue; }
        formAction +=
          `${(formAction[formAction.length - 1] === '/' ? '' : '/') +
formFieldsUpdUrl[i].value}/`;
      }
      formData = formData.replace(/\&$/, '');
      formData = formData === '?' ? '' : formData;
    }

    if (isEncodeFormData) {
      formData = encodeURIComponent(formData);
    }

    formAction = formAction.replace(/\/\//g, '/');

    if (!isAjaxRequestNeeded) {
      // Don't send the XHR request, yet we still need to keep the changes to
      // pathname & empty params stripped
      const data = formData ? `?${formData}` : '';
      location.href = `${location.origin}${formAction}${data}`;
    } else {
      // Setting the attribute "this form's request has alredy been sent" to
      // prevent submitting again before the responce is recieved
      ajaxTools.setTriggerLoading($this[0]);

      // Sending the request
      $.ajax({
        url: formAction,
        method: $this[0].getAttribute('method'),
        cache: false,
        data: formData,
        success(result) {
          const callback = $this[0].getAttribute('data-ajax-callback');

          if ($target !== null) { $target.html(result); }
          ajaxTools.unsetTriggerLoading($this[0]);

          // If browser history update is needed (new url in the address bar)
          if (shallUpdateHistory) {
            const data = formData ? `?${formData}` : '';
            history.pushState({}, 'Next page', `${formAction}${data}`);
          }

          // Init all smartdate elements inside the HTML being loaded
          if (window.smartdate) {
            window.smartdate.render();
          }

          // And we're done; removing the Loading class
          $this.removeClass(formLoadingClass);
          $this.addClass(formSuccessClass);

          if (callback && window[callback]) { window[callback](); }
        },
        error() {
          ajaxTools.unsetTriggerLoading($this[0]);
          $this.addClass(formErrorClass);
          $this.removeClass(formLoadingClass);
        },
      });
    }

    return false;
  });

  // -----------------------------------------------------------------------
  // Monitoring history updates
  // -----------------------------------------------------------------------

  // ... and updating HTML elements on the page, if needed
  // The backend should send "X-TargetContainerSelector" response header with
  // XHR responses, containing the selector of an element to be updated

  // Chrome 34-, Safari 10- fire onpopstate even on the initial page load,
  // thus resulting in an extra ajax call. Preventing that:
  ((() => {
    let blockPopstateEvent = document.readyState !== 'complete';
    // There's nothing to do for older browsers ;)
    if (!window.addEventListener) {
      return;
    }
    window.addEventListener('load', () => {
      // The timeout ensures that popstate-events will be unblocked right
      // after the load event occured, but not in the same event-loop cycle.
      setTimeout(() => { blockPopstateEvent = false; }, 0);
    }, false);
    window.addEventListener('popstate', (evt) => {
      if (blockPopstateEvent && document.readyState === 'complete') {
        evt.preventDefault();
        evt.stopImmediatePropagation();
      }
    }, false);
  })());

  /**
   * Checks if the form control has a given value (for a <select> - among its
   * options, etc.) and sets it to that value if it does.
   * For controls other than (<select>, <input type="radio">) returns false
   *
   * @param {Object} options
   * @param {Node} options.control -- the element to check and set
   * @param {String} options.value -- the value that needs to be checked
   * @param {Boolean} options.reset -- if true, just reset the element
   *
   * @return {Boolean} true, if the value is found and set
   */
  function formControlSetValueIfPresent(options) {
    const control = options.control;
    const value = options.value;
    let i;
    let option;

    // <select>
    if (control.tagName === 'SELECT') {
      if (options.reset) {
        control.options[0].selected = true;
        return true;
      }

      for (i = 0; i < control.options.length; i++) {
        option = control.options[i];
        if (option.value === value) {
          control.value = value;
          return true;
        }
      }
      return false;
    }

    // <input type="radio">
    if (control.length && control[0].type === 'radio') {
      if (options.reset) {
        for (i = 0; i < control.length; i++) {
          control[i].checked = false;
        }
        return true;
      }
      for (i = 0; i < control.length; i++) {
        if (control[i].value === value) {
          control[i].checked = true;
          return true;
        }
      }
      return false;
    }

    return false;
  }

  /**
   * Sets a value for an entity returned by form.elements.elName
   * Such can be a NodeList of Radios/Checkboxes, just setting control.value
   * won't do. And also to avoid eslint's no-param-reassign warning
   *
   * TODO: move it to mediaUtils?
   *
   * @param {Object} options
   * @param {Node} options.control -- the element to check and set
   * @param {String} options.value -- the value that needs to be checked
   * @param {Boolean} options.reset -- if true, just reset the element
   *
   * @return {Boolean}
   */
  function formControlSetValue(options) {
    const control = options.control;
    const value = options.value;
    let i;

    // <input type="radio">
    if (control.length && control[0].type === 'radio') {
      if (options.reset) {
        for (i = 0; i < control.length; i++) {
          control[i].checked = false;
        }
        return true;
      }
      for (i = 0; i < control.length; i++) {
        if (control[i].value === value) {
          control[i].checked = true;
          return true;
        }
      }
      return false;
    } if (control.length && control[0].type === 'checkbox') {
      // <input type="checkbox">
      if (options.reset) {
        for (i = 0; i < control.length; i++) {
          control[i].checked = false;
        }
        return true;
      }
      for (i = 0; i < control.length; i++) {
        control[i].checked = control[i].value === value;
      }
      return true;
    } if (control.tagName === 'SELECT') {
      // <select>
      if (options.reset) {
        control.options[0].selected = true;
        return true;
      }
      control.value = value;
      return true;
    }

    // All other cases (text inputs, emails, etc.)
    control.value = options.reset ? '' : value;
    return true;
  }

  window.addEventListener('popstate', () => {
    /**
     * jQuery ajax success callback
     * Reads the 'X-TargetContainerSelector' response header, looks for els
     * matching that selector, updates their HTML and updates the controls of
     * the forms and\or classes of the links associated with them
     *
     * @param {String} responseText - HTML as text
     * @param {Number} status - status code, e.g. 200. Not used.
     * @param {Object} jqXHR - jQuery wrapper aroung XMLHttpRequest object
     */
    function ajaxSuccess(responseText, status, jqXHR) {
      const targetSelector = (
        jqXHR.getResponseHeader('X-TargetContainerSelector')
      );
      let ajaxTargets = [];
      // Links/forms that trigger history pushes
      const ajaxTriggers = [].slice
        .call(document.querySelectorAll(`[${ajaxTools.historyUpdateAttr}]`));
      // Triggers that are "active" (associated with the updated targets and
      // having hrefs equal to location.href
      let activeTriggers = [];
      let i;

      if (targetSelector === undefined) { return; }

      ajaxTargets = [].slice.call(document.querySelectorAll(targetSelector));
      // Scrolling to the 1st (and probably the only) updated container.
      // Especially endless loader, when on Back we could end up at the bottom
      // of the screen. Not very convenient and prone to accidental "More" click
      // triggering
      if (ajaxTargets.length >= 1) {
        mediaUtils.scrollTo(ajaxTargets[0], { duration: 600 });
      }
      // Updating the targets' HTML
      for (i = 0; i < ajaxTargets.length; i++) {
        ajaxTargets[i].innerHTML = responseText;
      }

      activeTriggers = ajaxTriggers.filter((trigger) => {
        const triggersTarget = document
          .querySelector(trigger.getAttribute('data-ajax-target'));
        if (ajaxTargets.includes(triggersTarget) && (
          trigger.tagName.toLowerCase() === 'form' ||
          trigger.getAttribute('href') === location.pathname + location.search
        )) { return true; }
        return false;
      });

      // Updating associated forms and links
      // Have to use jQuery for .closest()
      $(activeTriggers).each(function () {
        const $this = $(this);
        let $parent;
        const successClassExtra = $this[0]
          .getAttribute(ajaxTools.ataxTriggerSuccessClassAttr);
        let urlParams;
        let pathnameParts;
        let formElements;
        let elementUpdated;

        // Extra class for active links
        if (successClassExtra &&
          $this[0].getAttribute('href') ===
            location.pathname + location.search
        ) {
          $parent = $this.closest(ajaxTools.ajaxScopeSel);
          if (successClassExtra) {
            if ($parent.length) {
              // Rm the extra class and :focus from the trigger's "siblings"
              $parent.find(ajaxTools.ajaxTriggerLinkSelector)
                .removeClass(successClassExtra).trigger('blur');
            }
            // Add extra success class to the current element
            $this.addClass(successClassExtra);
          }
        }

        // If it's a form - traverse its fields, find params in the URL with
        // corresponding names and update the fileds' values/
        // ONLY WORKS with text/date inputs, radios and selects for now
        // (no checkboxes)

        if ($this[0].tagName.toLowerCase() === 'form') {
          formElements = [].slice.call($this[0].elements);
          // Array of {name, value} based on the query string
          urlParams = mediaUtils.parseUrl(location.href);
          // Array of location.pathname parts
          pathnameParts = location.pathname.split('/')
            .filter((el) => el !== '');

          formElements.forEach((element) => {
            // If this el's value contributes to a location.pathname, we're
            // updating it based on the current pathname
            if (element.hasAttribute('data-ajax-url-path-updater')) {
              elementUpdated = false;
              pathnameParts.forEach((part) => {
                if (formControlSetValueIfPresent({
                  control: element,
                  value: part,
                })) {
                  elementUpdated = true;
                }
              });
              // If no part of the pathname worked, just setting it to ''
              if (!elementUpdated) {
                formControlSetValueIfPresent({
                  control: element,
                  reset: true,
                });
              }
            } else if (urlParams[element.name] !== undefined) {
              // Corresponding query parameter is set
              // using a function as a) it can be NodeList of radios,
              // b) for eslints no-param-reassign
              formControlSetValue({
                control: element,
                value: urlParams[element.name],
              });
            } else {
              // If there is no Get-param in a query - just reset
              formControlSetValue({
                control: element,
                reset: true,
              });
            }
          });
        }
      });
    }

    // Setting this flag for the Load more onscroll event not to fire on
    // a popstate history step back that leaves a user at the page bottom
    if (!window.siteState) { window.siteState = {}; }
    window.siteState.popstateScrollBlock = true;

    // Sending the request
    $.ajax({
      url: location.href,
      cache: false,
      // This param will allow the backend to distinguish, for example,
      // initial Load more calls and Back/Prev calls to the same urls and
      // send either just a number of rows or the whole container
      data: { historyWalk: 1 },
      success: ajaxSuccess,
    });
  });

  return true;
});
