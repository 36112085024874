/* global $, cub, dataLayer, mediaUtils */

// WARNING! Don't use jQuery here, or blank pages will get broken!

mediaUtils.domReady(() => {
  function CubLeadFormsSettings() {
    const modalWindowSelector = '[data-modal-form]';

    /**
     * Sets a cookie, most likely that a popup lead form has already been
     * shown to a user
     *
     * @param {string} cookieName - a cookie name to set
     *
     * @return {boolean/string} A cookie value (as of now - a string
     * representation of a date, like "Thu, 26 Oct 2017 05:49:25 GMT"), or
     * `false` if the cookie is not set.
     */
    function _setCookie(cookieName) {
      // get current date + 1 day
      const date = new Date();

      if (!cookieName) {
        return false;
      }

      date.setDate(date.getDate() + 1);
      // date will be written to `expires`
      mediaUtils.setCookie(cookieName, '1', date.toGMTString());
      return date.toGMTString();
    }

    /**
     * Init and show the modal lead form. For now used on domcontentloaded
     * TODO: remove the method and put all the code in the domcontentloaded
     * handler itself
     */
    this.showModal = () => {
      [].slice.call(document.querySelectorAll(modalWindowSelector))
        .forEach((modal) => {
          if (modal.getAttribute('data-modal-form-auto-open') !== '1') {
            return true;
          }

          const $modal = $(modal);
          const form = modal.querySelector('.Form--popup');
          const cookieName = form && form.getAttribute('data-cookieName');

          // Show the modal
          $modal.modal();

          // Set a cookie upon hiding the modal
          $modal.on('hide.bs.modal', () => {
            _setCookie(cookieName);
          });

          // If one modal with auto opening is found, don't bother checking
          // others even if they too have this attr
          return false;
        });
    };

    /**
     * Success callback for a cub widget form
     *
     * @param {Object} formData
     * @param {Node} formElement
     */
    this.onSuccess = (formData, formElement, response) => {
      const form = formElement.closest('[data-cub-form-container]');
      // For each async code in this function, we have to resolve this in that
      // code's callback. So that the Register Me redirect or any other built
      // in Cub functionality that always fire on form submit wouldn't tamper
      // with that async code working.
      const cubDeferredHelper = window.cub ? new cub.helpers.Deferred() : null;
      // Important: increment when entering every code chunk that would
      // trigger anything async, and decrement when that async code is done
      // working. Also make sure to check if the external lib responsible for
      // that async code (i.e., google_tag_manager, jstag, etc.) is loaded,
      // and if it's not, DON'T increase that counter 'cause the async code
      // will never run, the counter won't get decremented and the Deferred
      // will have to wait for the maxTimeout to elapse
      let asyncReturned = 0;

      const successFunction = (
        form && form.getAttribute('data-cub-submit-onsuccess')
      );
      const modalWrapper = form && form.closest(modalWindowSelector);

      // If there are elements on the page that need to change class attr on
      // this form successful submit
      const successToggleTarget = form &&
        form.hasAttribute('data-cub-success-toggle') &&
        document.querySelectorAll(
          form.getAttribute('data-cub-success-toggle'),
        );
      const successToggleTargetClass = (
        form && form.getAttribute('data-cub-success-toggle-class')
      );

      let gaTrackEvtType;
      let formType;

      let dataLayerObj;

      /**
       * Checks if all the events necessary for a final form submit/resolve
       * have happened and does the resolving
       *
       * @return {} nothing
       */
      function checkAndFireSuccess() {
        if (cubDeferredHelper && asyncReturned <= 0) {
          cubDeferredHelper.resolve();
        }
      }

      // Sending stuff to Lytics, asyncronously
      asyncReturned += 1;
      if (!mediaUtils.sendLyticsData(formData, () => {
        asyncReturned -= 1;
        checkAndFireSuccess();
      })) {
        // Our `sendLyticsData()` returns false if the data wasn't sent; if
        // that's the case, the async callback from above will never run, so
        // we decrement this counter right away.
        asyncReturned -= 1;
      }

      // Anything else is done only if there is a wrapper with all
      // corresponsing attributes
      if (!form) {
        checkAndFireSuccess();
        return cubDeferredHelper ? cubDeferredHelper.promise() : true;
      }

      // TODO: it's logical to apply these effects at the same moment as the
      // form's contents get updated (when success message gets rendered).
      // Can't find a way to do that now, maybe approach the Cub team for
      // an advice?
      if (successToggleTarget && successToggleTarget.length &&
        successToggleTargetClass
      ) {
        [].slice.call(successToggleTarget).forEach((target) => {
          // eslint-disable-next-line no-param-reassign
          target.className += ` ${successToggleTargetClass}`;
        });
      }

      // Tracking successful submits in GTM/GA, if needed
      if (form.getAttribute('data-cub-track') === 'submit' &&
        window.dataLayer
      ) {
        gaTrackEvtType = form.getAttribute('data-cub-track-type');
        formType = form.getAttribute('data-cub-form-type');

        // If GTM is not loaded, the `eventCallback()` will never run, so we
        // don't want to increase that counter
        // TODO: GTM might be not loaded YET, and when it does load, all data
        // in `dataLayer` will be sent. Remove that check?
        if (window.google_tag_manager) {
          asyncReturned += 1;
        }
        dataLayerObj = {
          // Different form types need different events (thus, different
          // triggers in GTM).
          event: gaTrackEvtType ?
            `CubLeadFormSubmit_${gaTrackEvtType}` : 'CubLeadFormSubmit',
          eventCategory: 'Cub Lead Form',
          eventAction: 'submit',
          eventCallback: () => {
            // Resolving the deferred object for the "Register Me" (and any
            // other future built-in Cub functions) to work properly
            // DON'T use hitCallback, it's for other setups (GA-only, and we
            // are using GTM as well)
            asyncReturned -= 1;
            checkAndFireSuccess();
          },
        };
        // If the form's type is specified, sending it along with the url
        // as a label
        if (formType) {
          dataLayerObj.eventLabel = `${formType},${location.pathname}`;
          dataLayerObj.leadFormType = formType;
        }
        dataLayer.push(dataLayerObj);
      }

      // Running a custom callback from inline JS (if there is one)
      if (successFunction && window[successFunction]) {
        window[successFunction](formData, formElement, response);
      }

      // Scrolling to the top of the form so that a user could read the
      // success message
      // Don't need to wait for that to finish for proceeding with RegisterMe
      // redirects/other forceful built-in Cub code
      if (!modalWrapper) {
        mediaUtils.scrollTo(form, { duration: 600 });
      }

      // If it's a modal form and it has a "Close this in ... ms", closing
      // that modal afterwards;
      // Don't need to wait for the modal to actually close for proceeding
      // with RegisterMe redirects/other forceful built-in Cub code
      if (modalWrapper &&
        modalWrapper.hasAttribute('data-modal-form-close-timeout')
      ) {
        // The modal close handler might not run in time, and the cookie won't
        // be set in that case. Making sure it is (Setting a cookie twice
        // won't hurt)
        _setCookie(form.getAttribute('data-cookieName'));
        setTimeout(() => {
          $(modalWindowSelector).modal('hide');
        }, modalWrapper.getAttribute('data-modal-form-close-timeout'));
      }

      checkAndFireSuccess();

      return cubDeferredHelper ? cubDeferredHelper.promise() : true;
    };

    return this;
  }

  window.cubLeadFormsSettings = new CubLeadFormsSettings();

  // modal lead form init
  window.cubLeadFormsSettings.showModal();
});
