/**
 * A set of common values and methods used in ajax functionality.
 * It's not meant to be a base-level ajax toolset, such as containing sth
 * similar to $.ajax (at least for now), just some functionality specific to
 * our sites
 * TODO: maybe later make it a module instead of a global object?
 */
// eslint-disable-next-line import/prefer-default-export
export const ajaxTools = {
  timestampAttr: 'data-request-sent-time',
  ajaxTriggerLinkSelector: '[data-ajax-load]',
  ajaxTriggerTargetAttr: 'data-ajax-target',
  // This attr can contain an extra class that would be used on the
  // button/link on successful load
  ataxTriggerSuccessClassAttr: 'data-ajax-class',
  // This attr can be given to a parent of multiple ajax buttios. All the
  // buttons inside it would be stripped of the success class
  ajaxScopeSel: '[data-ajax-links-scope]',
  historyUpdateAttr: 'data-history-update',

  /**
   * Sets the "this element's request is being processed" state for an ajax
   * request trigger element (a link, a form)
   *
   * @param {Node} element - an HTML element to set
   * @param {Boolean} forced - (optional) by default the method won't set the
   *    state if it has already been set.
   *
   * @return {Number or Boolean} the timestamp of the moment when the request
   *    has been sent, or `false` if the state is already set.
   */
  setTriggerLoading(element, forced) {
    const el = element;
    let timestamp;
    if (!el.hasAttribute(this.timestampAttr) || forced) {
      timestamp = new Date().valueOf();
      el.setAttribute(this.timestampAttr, timestamp);
      return timestamp;
    }
    return false;
  },

  /**
   * Unets the "this element's request is being processed" state for an ajax
   * request trigger element (a link, a form)
   *
   * @param {Node} element - a target HTML element
   */
  unsetTriggerLoading(element) {
    const el = element;
    el.removeAttribute(this.timestampAttr);
  },

  /**
   * Checks if an ajax request trigger element has the "this element's request
   * is being processed" state
   *
   * @param {Node} element - the target element
   *
   * @return {Number or Boolean} the timestamp of the moment when the request
   *    has been sent, or `false` if the state is already set.
   */
  checkTriggerLoading(element) {
    const timestamp = element.getAttribute(this.timestampAttr);
    if (timestamp && timestamp !== '') {
      return timestamp;
    }
    return false;
  },
};
