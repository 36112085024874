/* global mediaUtils */

/**
 * A set of temporary solutions for debugging stuff
 */
if (window.siteSettings.isStagingOrLocal) {
  const style = document.createElement('style');
  style.innerHTML = `
    .DebugBtns { position:fixed; z-index:10000; bottom:0; left:0; }
    .DebugBtn {
      display: block;
      min-width: 50px;
      height: 26px;
      margin-bottom: 10px;
      padding: 0 6px;
      line-height: 26px;
      cursor: pointer;
      background: blue;
      color:#fff;
      text-align:center;
      text-decoration:none;
      opacity: 0.7;
    }
    .DebugBtn:hover { color: #fff; text-decoration: none; opacity: 1; }
    .DebugBtn:last-child { margin: 0; }
    .DebugBtn.is-on { background: red; }
  `;
  document.body.appendChild(style);

  const debugBtns = document.createElement('div');
  debugBtns.className = 'DebugBtns';
  document.body.appendChild(debugBtns);

  // Switching between the main web fonts and their debug fonts
  const fontBtn = document.createElement('a');
  fontBtn.className = 'DebugBtn';
  fontBtn.innerText = 'Web';
  fontBtn.tabIndex = '-1';
  debugBtns.appendChild(fontBtn);

  fontBtn.addEventListener('click', (e) => {
    e.preventDefault();
    const docClasses = document.documentElement.classList;

    if (docClasses.contains('is-fontsNotLoaded')) {
      docClasses.remove('is-fontsNotLoaded');
      fontBtn.innerText = 'Web';
      fontBtn.classList.add('is-on');
    } else {
      docClasses.add('is-fontsNotLoaded');
      fontBtn.innerText = 'Backup';
      fontBtn.classList.remove('is-on');
    }
  });

  // A switch to using the non-jquery bundles
  const isJq = mediaUtils.checkCookie('is_no_jq') !== '1';
  const jqBtn = document.createElement('a');
  jqBtn.className = `DebugBtn ${isJq ? 'is-on' : ''}`;
  jqBtn.innerText = isJq ? 'jQuery' : 'no jQuery';
  jqBtn.tabIndex = '-1';
  debugBtns.appendChild(jqBtn);

  jqBtn.addEventListener('click', (e) => {
    e.preventDefault();
    mediaUtils.setCookie('is_no_jq', isJq ? '1' : '0');
    window.location.reload();
  });
}
