/* global DetectDeviceHelper */
/* eslint-disable */

// Just temporarily comment this to make old Gulp build work (CROSS-441)
import $ from 'jquery';
import _ from 'underscore';
import Choices from 'choices.js';


document.addEventListener('DOMContentLoaded', () => {
  let $header;
  let $pageContent;
  const $document = $(document);
  const $form = $('#mobile-search');
  let $input = $form.find('[data-control="input"]');
  let $btn;
  let $fileUpload;
  const $a = $form.find('[data-control="button"]');
  // The delay between the mobile search form input losing focus and
  // the form collapsing
  const time = 800;
  // A variable to store that timeout id
  let id;
  let isFormInputBlurNatural = true;
  const $html = $('html');
  const form = '#header-search';
  const footerAddValue = 30;
  let $h1;
  const $win = $(window);
  let lazyLayout;
  const $footer = $('#footer').first();
  let calculateSiteHeight;
  let headerHeight;
  // TODO: remove this JS hover funcitonality; a more universal method is
  // implemented down below (data-js-hover)
  const linkedSelectors = '.list-link li, ' +
    '.original-video .video-links-block, ' +
    '.sponsored-block .content, ' +
    '.winner-block, ' +
    '.image-links-block';
  const aLinkedSelectors = 'a:not(.Link)' +
    ':not(.btn.btn-danger)' +
    ':not(.social-bar a)' +
    ':not(.SocialLinks-link)' +
    ':not(h2.winner > a)';
  const artificialHoverSelector = '[data-js-hover]';
  const currentDevice =
    window.DetectDeviceHelper && DetectDeviceHelper.detectDevice();

  // add special hover for list-link-video block
  $document.on('mouseenter', aLinkedSelectors, function () {
    $(this).parents(linkedSelectors).addClass('active');
  });

  $document.on('mouseleave', aLinkedSelectors, function () {
    $(this).parents(linkedSelectors).removeClass('active');
  });

  // New way to utilize artificial JS hover: with just data-attr selector
  // This is needed for multiple sibling elements getting hover effect when
  // their parent gets mouse over it
  $document.on('mouseenter', artificialHoverSelector, function () {
    $(this).addClass('is-hover');
  });

  $document.on('mouseleave', artificialHoverSelector, function () {
    $(this).removeClass('is-hover');
  });

  function removeAndClear() {
    if (id) {
      clearTimeout(id);
    }
    $form.removeClass('active');
    $input.val('');
  }

  // Clicking the magnifying glass icon in the mobile search form
  $a.on('click', () => {
    if ($form.hasClass('active')) {
      // If nothing is entered, collapsing the form
      if ($input.val().trim() === '') {
        // Calling `removeAndClear` is not enough because that way the virt
        // keyboard might not go away (e.g. iOS 7 Safari)

        // Playing with `isFormInputBlurNatural` to make 0 delay for
        // collapsing the form
        isFormInputBlurNatural = false;
        $input.trigger('blur');
        isFormInputBlurNatural = true;
        return false;
      }
      // Otherwise sending it
      $form.submit();
    } else {
      if (!$input.is(':focus')) {
        $form.addClass('active');
        $input.trigger('focus');
      }

      if ($('#main-menu').hasClass('active')) {
        $('#menu-mobile-toggle-btn').trigger('click');
      }
    }

    return false;
  });

  // When the mobile search form input field loses focus
  $input.on('blur', () => {
    if (id) {
      clearTimeout(id);
    }
    // If the input is losing focus programmatically, rm the delay
    if (!isFormInputBlurNatural) {
      removeAndClear();
    } else {
      id = setTimeout(removeAndClear, time);
    }
  });
  // end mobile search

  // add event listener for search button at the top of header
  $(`${form} [data-control="button"]`).on('click', () => {
    $(form).submit();
    return false;
  });

  $(`${form}, #google-search-form`).on('submit', function (event) {
    if (!$(this).find('input[name="q"]').val()) {
      event.preventDefault();
    }
  });

  // submit form init (custom file upload control)
  const $fileUploadControl = $('#submit-form .file-upload');
  if ($fileUploadControl.length) {
    $input = $fileUploadControl.find('input[type="text"]');
    $btn = $fileUploadControl.find('.btn');
    $fileUpload = $fileUploadControl.find('input[type="file"]');

    $fileUpload
      .on('change', function () {
        $input.attr('value', this.value);
      })
      .on('mouseenter mouseleave', () => {
        const activeClass = 'active';
        if ($btn.hasClass(activeClass)) {
          $btn.removeClass(activeClass);
        } else {
          $btn.addClass(activeClass);
        }
      });
  }

  if ($('body').hasClass('responsive')) {
    //
    // A simple classname toggler
    // ------------------------------------------------------------------

    // <a class=".."
    //    data-toggle-show="#id-of-element"
    //    data-toggle-class-add="class-added-on-click-to-target"
    //    data-toggle-class-remove="class-removed-on-click-from-target"
    // >More</a>
    // If none of the classes are specified, just toggles "u-hidden"
    $document.on('click', '[data-toggle-show]', function (e) {
      const $this = $(this);
      const $target = $($this[0].getAttribute('data-toggle-show'));
      const targetClassAdd = $this[0].getAttribute('data-toggle-class-add') ?
        $this[0].getAttribute('data-toggle-class-add') : '';
      let targetClassRemove =
        $this[0].getAttribute('data-toggle-class-remove') ?
        $this[0].getAttribute('data-toggle-class-remove') : '';
      // We want to update the active classname for all UI elements that
      // control the same target element
      const comradeEls = $('[data-toggle-show="' +
        `${$this[0].getAttribute('data-toggle-show')}"]`);
      e.preventDefault();
      if (!targetClassAdd && !targetClassRemove) {
        targetClassRemove = 'u-hidden';
      }

      if ($this.hasClass('is-active')) {
        $target.addClass(targetClassRemove);
        $target.removeClass(targetClassAdd);
        comradeEls.removeClass('is-active');
      } else {
        $target.addClass(targetClassAdd);
        $target.removeClass(targetClassRemove);
        comradeEls.addClass('is-active');
      }
    });

    // If the toggle element has the `data-toggle-unfocus` attribute, then the
    // element's target should be untoggled when something outside it or the
    // toggle has been clicked
    document.documentElement.addEventListener('click', function (e) {
      const node = e.target;

      [].slice.call(
        document.querySelectorAll('[data-toggle-unfocus]')
      ).forEach(toggle => {
        const target = document
          .querySelector(toggle.getAttribute('data-toggle-show'));
        if (!toggle.contains(node) && !target.contains(node) &&
          toggle.classList.contains('is-active')
        ) {
          toggle.click();
        }
      });
    });
  }

  // Initialize combobox plugin
  $('[data-combobox]').each(function () {
    /* eslint-disable no-new */
    new Choices(this, {
      itemSelectText: '',
      shouldSort: false,
      searchResultLimit: 10,
      searchFields: ['label'],
    });
  });

  // Tabs
  document.documentElement.addEventListener('click', (e) => {
    const nav = e.target.closest('[data-tabs-nav]');
    if (!nav) { return; }

    const id = nav.getAttribute('data-tabs-nav').substring(1);
    const root = nav.closest('[data-tabs]');

    [].slice.call(root.querySelectorAll('[data-tabs-tab]')).forEach(tab => {
      if (tab.id === id) {
        tab.classList.add('is-active');
      } else {
        tab.classList.remove('is-active');
      }
    });

    [].slice.call(root.querySelectorAll('[data-tabs-nav]')).forEach(navItem => {
      navItem.classList.remove('is-active');
    });

    nav.classList.add('is-active');

    e.preventDefault();
  });
  // end Tabs
  
  $('[data-show-on-scroll]').each(function () {
    if (!(window.lazyAds && window.lazyAds.initEventHandlerToShowAdOnScroll)) {
      return;
    }

    let allowableDevicesAr = [];
    let isAllowedDevice = false;
    const allowableDevices = $(this).attr('allowable-devices');

    if (allowableDevices) {
      allowableDevicesAr = allowableDevices.split(',');
    }

    if (allowableDevicesAr.length) {
      allowableDevicesAr.forEach(value => {
        const allowableDevice = String(value).trim();
        if (allowableDevice === currentDevice) {
          isAllowedDevice = true;
        }
      });
      if (isAllowedDevice) {
        window.lazyAds.initEventHandlerToShowAdOnScroll($(this));
      }
    } else {
      window.lazyAds.initEventHandlerToShowAdOnScroll($(this));
    }
  });
});
