import _ from 'underscore';
import Cookies from 'js-cookie';

window.mediaUtils.domReady(() => {
  /* Use the 'mousedown' event in order to catch cases when a user open a link
  * using the right mouse click.
  */
  const clickEvent = !document.documentElement.classList.contains('desktop') ?
    'click' : 'mousedown';

  let trackingLinksFailedAttempts = 0;
  const trackingLinksAttemptsMax = 3;

  // selectors of elements that should be tracked
  const trackingLinkUrl = 'trackingLinkUrl' in window.siteSettings ?
    window.siteSettings.trackingLinkUrl : 'https://go.lexipol.com';
  const trackingImpressionSelector = `a[href*=\'${trackingLinkUrl}\'],
    [data-tracking-link*=\'${trackingLinkUrl}\']`;
  const trackingClickSelector = `a[href*=\'${trackingLinkUrl}\'],
    [data-tracking-link*=\'${trackingLinkUrl}\']`;
  const trackingPageViewSelector = '[data-tracking-page-view]';

  const impressions = [];
  let impressionsQueue = [];
  let clicks = [];

  const parseUrl = window.mediaUtils.parseUrl;

  function processError(ex) {
    const ie = document.all && !window.atob ||
      window.navigator.msPointerEnabled;
    // If not NetworkError from IE
    // TODO move the condition to utils
    if (ie && (!ex || ex.name === 'NetworkError')) {
      return;
    }
    if (window.Sentry) {
      window.Sentry.captureException(ex || new Error('tracking_request_error'));
    }
  }

  function getUserIdentity() {
    const result = {};
    if (window.siteSettings.userUid) {
      result.lid = window.siteSettings.userUid;
    }
    const lyticsId = Cookies.get('seerid');
    if (lyticsId) {
      result.lytics = lyticsId;
    }
    const gaClientId = Cookies.get('_ga');
    if (gaClientId) {
      result.ga = gaClientId;
    }
    const gaBehaviorId = Cookies.get('_gid');
    if (gaBehaviorId) {
      result.gid = gaBehaviorId;
    }
    return result;
  }

  function getVisibilityParams() {
    let visibilityChange;
    let visibilityHidden;
    // Opera 12.10 and Firefox 18 and later support
    if (typeof document.hidden !== 'undefined') {
      visibilityChange = 'visibilitychange';
      visibilityHidden = 'hidden';
    } else if (typeof document.mozHidden !== 'undefined') {
      visibilityChange = 'mozvisibilitychange';
      visibilityHidden = 'mozHidden';
    } else if (typeof document.msHidden !== 'undefined') {
      visibilityChange = 'msvisibilitychange';
      visibilityHidden = 'msHidden';
    } else if (typeof document.webkitHidden !== 'undefined') {
      visibilityChange = 'webkitvisibilitychange';
      visibilityHidden = 'webkitHidden';
    }

    return {
      change: visibilityChange,
      hidden: visibilityHidden,
    };
  }

  function sendImpressions() {
    if (impressionsQueue.length > 0 &&
      trackingLinksFailedAttempts < trackingLinksAttemptsMax) {
      const links = impressionsQueue.slice();
      const result = [];

      links.forEach((link) => {
        result.push(parseUrl(link));
      });

      const sendXMLHttpRequest = (url, params) => {
        const request = new XMLHttpRequest();
        request.open('POST', url);
        request.onload = () => {
          impressionsQueue = impressionsQueue.filter(
            (el) => links.indexOf(el) === -1,
          );
        };
        request.onerror = () => {
          trackingLinksFailedAttempts += 1;
          processError();
        };
        request.send(params);
      };

      const url = `${trackingLinkUrl}/api/impressions/`;
      const params = new FormData();
      params.append('links[]', JSON.stringify(result));
      const identity = getUserIdentity();
      Object.keys(identity).forEach((k) => {
        params.append(`identity[${k}]`, identity[k]);
      });

      if (navigator.sendBeacon) {
        try {
          if (navigator.sendBeacon(url, params)) {
            impressionsQueue = impressionsQueue.filter(
              (el) => links.indexOf(el) === -1,
            );
          } else {
            trackingLinksFailedAttempts += 1;
          }
        } catch (ex) {
          sendXMLHttpRequest(url, params);
        }
      } else {
        sendXMLHttpRequest(url, params);
      }
    }
  }

  const debouncedSendImpressions = _.debounce(
    sendImpressions, 300,
  );

  function pushLinkToQueue(link) {
    impressions.push(link);
    impressionsQueue.push(link);
    debouncedSendImpressions();
  }

  //
  // send impressions
  //
  [].slice.call(document.querySelectorAll(trackingImpressionSelector)).forEach(
    (elem) => {
      /* eslint-disable-next-line no-new */
      new window.Waypoint({
        element: elem,
        handler: () => {
          const link = elem.getAttribute('data-tracking-link') ||
            elem.getAttribute('href');
          if (link && impressions.indexOf(link.replace('&ns=1', '')) === -1) {
            pushLinkToQueue(link);
          }
        },
        offset: '100%',
        continuous: true,
      });
    },
  );

  //
  // send clicks
  //
  document.addEventListener(clickEvent, (e) => {
    if (e.target.nodeType !== Node.ELEMENT_NODE) { return; }
    const linkElement = e.target.closest(trackingClickSelector);
    if (linkElement) {
      const link = linkElement.getAttribute('data-tracking-link') ||
        linkElement.getAttribute('href');

      const dontSaveServerClick = () => {
        const href = linkElement.getAttribute('href');
        if (href && href.includes(trackingLinkUrl) &&
          !(/ns=1$/.test(href))) {
          linkElement.href = `${link}&ns=1`;
        }
      };

      const sendXMLHttpRequest = (url, params) => {
        const request = new XMLHttpRequest();
        request.open('POST', url, false);
        try {
          request.send(params);
          if (request.status >= 200 && request.status < 400) {
            dontSaveServerClick();
          }
        } catch (ex) {
          processError(ex);
        }
      };

      if (link) {
        // we save only one click per link on a page
        // we use array for the case of 'Open link in new tab' multiple clicks
        if (clicks.indexOf(link.replace('&ns=1', '')) === -1) {
          clicks.push(link.replace('&ns=1', ''));
        } else {
          return;
        }
        // we get api url from the link itself or send to the same url
        const url = `${trackingLinkUrl}/api/clicks/`;
        const params = new FormData();
        params.append(
          'link',
          JSON.stringify(parseUrl(link.split('?')[1])),
        );
        const identity = getUserIdentity();
        Object.keys(identity).forEach((k) => {
          params.append(`identity[${k}]`, identity[k]);
        });

        if (navigator.sendBeacon) {
          try {
            if (navigator.sendBeacon(url, params)) {
              // if we successfully queued the data for transfer
              dontSaveServerClick();
            }
          } catch (ex) {
            sendXMLHttpRequest(url, params);
          }
        } else {
          sendXMLHttpRequest(url, params);
        }
      }
    }
  });

  const visibilityParams = getVisibilityParams();
  // empty sent tracking links array when we leave the page
  // so, user will be able to generate clicks from the same links
  document.addEventListener(visibilityParams.change, () => {
    if (document.visibilityState === visibilityParams.hidden) {
      clicks = [];
    }
  });

  //
  // send page views
  //
  [].slice.call(document.querySelectorAll(trackingPageViewSelector)).forEach(
    (elem) => {
      const sendXMLHttpRequest = (url, params) => {
        const request = new XMLHttpRequest();
        request.open('POST', url);
        request.onerror = () => {
          processError();
        };
        request.send(params);
      };

      const pageView = elem.getAttribute('data-tracking-page-view');
      if (pageView) {
        const url = `${trackingLinkUrl}/api/pageview/`;
        const params = new FormData();
        params.append('page-view', JSON.stringify(parseUrl(pageView)));
        if (navigator.sendBeacon) {
          try {
            navigator.sendBeacon(url, params);
          } catch (ex) {
            sendXMLHttpRequest(url, params);
          }
        } else {
          sendXMLHttpRequest(url, params);
        }
      }
    },
  );

  //
  // send impressions for the endless loader
  //
  const targetElements = document.querySelectorAll(
    '[data-load-more-target]',
  );

  if (targetElements.length !== 0) {
    const target = targetElements[0].parentNode;
    const MutationObserver = window.MutationObserver ||
      window.WebkitMutationObserver || window.MozMutationObserver;
    if (MutationObserver) {
      const observer = new MutationObserver(() => {
        const targetLinks = target.querySelectorAll(trackingImpressionSelector);
        for (let i = 0; i < targetLinks.length; i++) {
          const link = targetLinks[i].getAttribute('data-tracking-link') ||
            targetLinks[i].getAttribute('href');

          if (link && impressions.indexOf(link.replace('&ns=1', '')) === -1) {
            pushLinkToQueue(link);
          }
        }
      });
      observer.observe(target, { childList: true });
    }
  }

  // Tracking the DPR (pixel density) of a user's device screen
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAEvent',
    eventCategory: 'Screen Properties',
    eventAction: 'Device Pixel Ratio',
    eventLabel: Math.round(20 * window.devicePixelRatio) / 20,
    // Use a non-interaction event to avoid affecting bounce rate.
    nonInteraction: true,
  });
});
