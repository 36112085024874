/* global siteState, mediaUtils */

import $ from 'jquery';

import { ajaxTools } from 'ajax/ajax-tools.js';


$(document).ready(() => {
  const loadMoreTriggerSel = '[data-load-more-trigger]';
  const loadMoreTargetSel = '[data-load-more-target]';
  const loadingClass = 'is-processing';

  // Auto scrolling works only on desktop.
  // "desktop" class is added by device.js.
  if ($('html').hasClass('desktop')) {
    // Trigger the click on the last "load more" button on the page when
    // a user has scrolled to the very bottom
    window.addEventListener('scroll', () => {
      // Checking if the scroll is artifical (like animating the scrollTop)
      if (window.siteState && siteState.artificalScrollBlock === true) {
        return;
      }

      // If a user presses "Back" and a poptate history step occures, they
      // could very well end up at the very end of the page; and this
      // onscroll will trigger the Load more right away. To prevent that we
      // are checking `siteState.popstateScrollBlock`; if it's true (a user
      // just pressed "Back"), reset it and do nothing. That way only the real
      // scrolling to the bottom will trigger the Load more.
      // Adding +1px in the 2nd part because some browsers can have fractions
      // of pixels in such values, especially if the scale has changed
      if (window.siteState && siteState.popstateScrollBlock === true) {
        siteState.popstateScrollBlock = false;
      } else if (window.pageYOffset + window.innerHeight + 1 >=
        document.body.scrollHeight
      ) {
        // Trigger the load more if the user scrolled to the very bottom
        const triggers = document.querySelectorAll(loadMoreTriggerSel);
        if (triggers.length) {
          triggers[triggers.length - 1].click();
        }
      }
    });
  }

  $(document).on('click', loadMoreTriggerSel, function () {
    const $this = $(this);
    const shallUpdateHistory = $this[0].hasAttribute(
      ajaxTools.historyUpdateAttr,
    );
    const url = $this[0].getAttribute('href');

    if (ajaxTools.checkTriggerLoading($this[0])) {
      return false;
    }

    ajaxTools.setTriggerLoading($this[0]);

    const $container = $this.closest(loadMoreTargetSel);
    $this.addClass(loadingClass);

    $.get(url, (data) => {
      const $data = $(data);

      // For better ux we are scrolling the doc to where the new records have
      // loaded to. And here we are chosing, where exactly. If there is some
      // element before them and it's not too tall, scroll to it - that way it
      // is cleare: these are the new els. Otherwise, scroll to the load more
      // container itself
      const prevElement = $container[0].previousElementSibling;
      const scrollToElement = prevElement &&
        prevElement.clientHeight < window.innerHeight / 3 ?
        prevElement : $container[0];

      mediaUtils.scrollTo(scrollToElement, { duration: 600 });

      $data.each(function (i) {
        const delay = `${0.04 * i}s`;

        $(this)
          .addClass('u-fadeInMToTop')
          .css({
            '-webkit-animation-delay': delay,
            'animation-delay': delay,
          });
      });
      $container.before($data).remove();

      // Init all smartdate elements inside the HTML being loaded
      if (window.smartdate) {
        window.smartdate.render();
      }
      $this.removeClass(loadingClass);

      ajaxTools.unsetTriggerLoading($this[0]);

      // If browser history update is needed (new url in the address bar)
      if (shallUpdateHistory) {
        // On "ajax" history changes we don't want automatic scrollbacks
        // from the browser (in supported browsers)
        if ('scrollRestoration' in history) {
          history.scrollRestoration = 'manual';
        }
        history.pushState({}, 'Next page', url);
      }
    });

    return false;
  });
});
