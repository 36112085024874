import smartdate from 'smartdate';


smartdate.locale.en.originalTime = smartdate.locale.en.time;
smartdate.locale.en.time = function (date) {
  return this.originalTime(date)
    .replace(/pm|am/g, (s) => s.toUpperCase());
};

function futurePastCorrection(dateOut) {
  let minute = 'minute';
  // checking for the one minute
  minute += (/(?:\D|^)1\D/g.test(dateOut)) ? ' ' : 's ';
  // replacing "min" to "minutes"
  return dateOut.replace(/min(?: |$)/g, minute);
}

smartdate.locale.en.originalPast = smartdate.locale.en.past;
smartdate.locale.en.past = function (date) {
  return futurePastCorrection(this.originalPast(date));
};

smartdate.locale.en.originalFuture = smartdate.locale.en.future;
smartdate.locale.en.future = function (date) {
  return futurePastCorrection(this.originalFuture(date));
};

smartdate.init({
  capitalize: true,
});
